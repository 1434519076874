@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');



.App {
  display: flex;
  flex-direction: column;
  width: 99.1vw; 
  justify-content: center;
  background-size: cover;  
  text-align: center;
  background-image: url(./imagens/FundoSorteioAman2.jpg);  
  background-color: #1f3a79;
  background-position: center;
  background-repeat: no-repeat;
  border-style: hidden;
  font-family: 'Montserrat', sans-serif;
  position: relative; /* Adicione a posição relativa para que a sobreposição seja posicionada corretamente */  
}
@media (min-width:1601px){
  .App{
    min-height: 160vh;
  }
}
@media (max-width:1600px){
  .App{
    min-height: 175vh;
  }
}
@media (max-width:1599px){
  .App{
    min-height: 260vh;
  }
}


.App Button{
  font-size: 30px;
  color: #FFFFFF;
  background-color: #19334D;
  font-weight: 600;
  padding: 6px 40px;
  border-radius: 15px;
  margin-top: 75px;
  margin-bottom: 150px;
  font-family: 'Montserrat', sans-serif;
  transition: background-color 0.7s ease;
}

.App Button:hover {
  color: #19334D;
  background-color: #FFFFFF;
}

.App .MuiGrid-container {
  display: flex;  
  align-items: center;
  justify-content: center; 
  font-family: 'Montserrat', sans-serif;
}

.App h2 {
  color: #ffffff;
  font-size: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  justify-self: center;
  font-family: 'Montserrat', sans-serif;
}
.switch-label {
  font-size: 25px; 
  font-family: 'Montserrat', sans-serif;
}
.inicial{
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center; 
  overflow: hidden;
  text-align: center;
   
  background-color: #1f3a79;
  background-position: center;
  background-repeat: repeat;
  border-style: hidden;
  font-family: 'Montserrat', sans-serif;
}
@media (max-width:768px) {
  .inicial {
    background-image: url(./imagens/FundoSorteioAman.jpg); 
    background-size: cover;
    min-height: 90vh;
  }
  }
@media (min-width:767px) {
  .inicial {
    background-image: url(./imagens/FundoSorteioAman.jpg); 
    background-size: cover;
    min-height: 90vh;
  }
  }
@media (min-width:1201px) {
  .inicial {
    background-image: url(./imagens/FundoSorteioAman.jpg); 
    background-size: cover;
    min-height: 90vh;
  }
  }

.inicial h1{
  
  font-family:  'Montserrat',Black, sans-serif;
  color: #FFFFFF;
  font-weight: 900;
}

/* @media (max-width:810px) {
.inicial h1{
  font-size: 50px;
}
} */
/* escurecer o fundo da tela */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Cor de fundo semitransparente */
  overflow: hidden;
}
 



.contador {
  position: absolute;  
  background-color: #19334D;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 50%;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
}

.contador{
  top: 8%;
  right: 5%;
}

.inicial img{
  position:relative;
  display: flex;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

  /* .inicial img:first-child{
    height: 400px;    
  } */
  .inicial img:last-child{
    height: 150px;
  }


.inicial Button{
  position: relative; 
  color: #FFFFFF;  
  background-color: #19334D;
  font-weight: 600;
  padding: 2px;
  border-radius: 15px;
  font-family: 'Montserrat', sans-serif;  
  width: 360px;  
  font-size: 30px;
  transition: background-color 1s ease;
  margin-bottom: 15px;
}
.inicial Button:hover {
  color: #19334D;
  background-color: #FFFFFF;
} 

 /*  .inicial Button:first-child {
    margin-bottom: 10px;
  }
  .inicial Button:last-child {
    bottom: 25%;
  }  */ 


/*
@media (max-width: 1366px) and (max-height: 767px)  {  
  
  .inicial  Button{
    bottom: -10%;
    width: 340px;  
    font-size: 28px;
 
}

.inicial Button:first-child {
    right: 10%;  
  }  
  .inicial Button:last-child {
    left: 10%;  
  } 
}
@media (max-width: 1366px) and (min-height: 768px)  {  
  
  .inicial  Button{
    bottom: 22%;
    
 
}

.inicial Button:first-child {
    left: 4%;  
  }  
  .inicial Button:last-child {
    right: 4%;  
  } 
}

@media (min-width: 1367px) {  
 
  .inicial  Button{
    bottom: 22%;
    width: 360px;  
    font-size: 36px;
  }

  .inicial Button:first-child {
    left: 20%;  
  }  
  .inicial Button:last-child {
    right: 20%;  
  }  
} */

.Sorteio{
  font-weight: bolder;
  font-size: 40px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.css-ahj2mt-MuiTypography-root {
  font-family: 'Montserrat',  sans-serif !important;
}
.css-ag7rrr-MuiTypography-root{
  font-family: 'Montserrat',  sans-serif !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-datepicker {
  background-color: #F8F8F8;
  color: #FFFFFF ;
  font-size: 25px;
  width: 145px;
  height: auto;
  border-radius: 3px;
  border-style: initial;
  font-family: 'Montserrat', sans-serif;
}
.App-header {
  background-color: #000000;
  /*min-height: 1px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.App-link {
  color: #61dafb;
  font-family: 'Montserrat', sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.form-field{
  color: white; /* Define a cor do texto como branco */
  background-color: rgba(255, 255, 255, 0.9);  
  font-size: 25px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 20px;*/
  height: 40px;
  background-color: #010101f5;
  font-family: 'Montserrat', sans-serif;
}



.logo :first-child{  
  height: 130px;
}
.logo :last-child{
  height: 80px;
}
@media (min-width: 1370px) {
  .logo {
    width: 150px;    
  }
}
@media (max-width: 1369px) {
  .logo {
    width: 140px;    
  }
}
@media (max-width: 1199px) {
  .logo {
    width: 120px;    
  }
}
@media (max-width: 999px) {
  .logo {
    width: 100px;    
  }
}
@media (max-width: 799px) {
  .logo {
    width: 90px;    
  }
}
@media (max-width: 599px) {
  .logo {
    width: 65px;    
  }
}


.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}


.modal-content {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px; 
  justify-content: space-between; 
  font-family: 'Montserrat', sans-serif;
}

.modal-content-inicial {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  min-height: 70%;
  max-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}
.css-o2w69a-MuiTypography-root{
  font-family: 'Montserrat', sans-serif;
}
.modal-content-inicial .scrollable-content {
  flex: 1;
  overflow-y: auto;
  text-align: left;
}

.modal-content-inicial button {
  align-self: center;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #FFFFFF;
  background-color: #213a5b;
  font-weight: 600;
  padding: 6px 40px;
  border-radius: 15px;
  font-family: 'Montserrat', sans-serif;
}

.modal-content-inicial h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
}

.modal-body {
  margin-top: 20px;
}
.modal-content h1 {
  margin-top: 40px;
  font-size: 64px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content p {
  margin-top: 40px;
  font-size: 45px;
  font-family: 'Montserrat', sans-serif;
}

.modal-content button {
  margin-bottom: -90px;
  font-size: 36px;
  color: #FFFFFF;
  background-color: #19334D;
  font-weight: 600;
  padding: 6px 40px;  
  border-radius: 15px;
  font-family: 'Montserrat', sans-serif;
}


@media(max-width:800px){
  .modal-content{   
    padding: 130px;
  }
  .modal-content h1{
    font-size: 64px;
  }
  .modal-content p{
    font-size: 40px;
  }
  .modal-container button{
    font-size: 32px;    
  }
}
@media(max-width:670px){
  .modal-content{   
    padding: 100px;
  }
  .modal-content h1{
    font-size: 55px;
  }
  .modal-content p{
    font-size: 40px;
  }
  .modal-container button{
    font-size: 32px;
    margin-bottom: -75px;
  }
}
@media(max-width:600px){
  .modal-content{   
    padding: 65px;
    /* min-height: 600px; */
  }
  .modal-content h1{
    font-size:46px;
  }
  .modal-content p{
    font-size: 35px;
  }
  .modal-container button{
    font-size: 24px;
    margin-bottom: -25px;
  }
}

.table {
  width: 80%;
  font-family: 'Montserrat', sans-serif;
}

.table th {
  background-color: rgba(41, 170, 225, 0.55);
  color: #000000;
  font-weight: bold;
  font-size: larger;
  text-align: center;
  border-style: solid;
  border-bottom: solid;
  border-color: white;
}

.table td {
  background-color: rgba(25, 51, 76, 0.9569);
  color: #F8F8F8 ;
  font-size: larger;
  padding: 8px;  
  text-align: center;  
  border-style: solid;
  border-bottom: solid;
  border-color: white;
}

.table th:nth-child(1),
.table td:nth-child(1) {
  width: 10%; /* Largura da primeira coluna */
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 30%; /* Largura da segunda coluna */
}

.table th:nth-child(3),
.table td:nth-child(3) {
  width: 30%; /* Largura da terceira coluna */
}


.table .highlight {
  background-color: rgba(255, 217, 0, 0.25);
  font-family: 'Montserrat', sans-serif;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: 'Montserrat', sans-serif;
}

input.invalid {
  border: 2px solid red;
  border-radius: 5px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{  
  text-align: center;
}

